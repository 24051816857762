import { InferOutput, boolean, number, object, optional, picklist, string, union } from 'valibot'
import { Database } from '~/types/supabase'

export interface ExportModalProps {
  open: boolean
  setOpen: (value: boolean) => boolean
  chapterId: Database['public']['Tables']['chapters']['Row']['id']
}

export const ChapterExportSchema = object({
  width: number(),
  widthPreset: union([number(), string()]),
  splitIntoChunks: boolean(),
  chunkSize: optional(number()),
  format: picklist(['png', 'jpeg'])
})

export type ChapterExportFormType = InferOutput<typeof ChapterExportSchema>