import { imageLoader } from '~/editor/image/image'
import { getPagesURLs } from '~/server/export/translation/page-images'
import { getTypesetPage } from '~/server/export/typesetting/typeset-page'
import { ExportTypesetPagesPayload } from '~/types/canvas/exported-page'

const getTypesetPages = async ({ format, pages, typesetTexts, width, chunkSize }: ExportTypesetPagesPayload) => {
  const imagesURLs = await getPagesURLs(pages)
  const imageLoaders = imagesURLs.map(url => imageLoader(url))
  const imagesTags = await Promise.all(imageLoaders)
  const indexedPages = await Promise.all(
    pages.map(async (page, index) => {
      const pageImage = imagesTags[index]
      const pageTypesetTexts = typesetTexts.filter(entry => entry.page_id === page.id)
      return await getTypesetPage({
        image: pageImage, 
        typesetTexts: pageTypesetTexts, 
        width, 
        chunkSize, 
        format
      })
    })
  )
  return indexedPages
}

export {
  getTypesetPages
}