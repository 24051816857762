import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { getPages } from '~/services/database/pages'
import { ExportChapterTypesettingPayload } from '~/types/database/chapter'
import { getSeries } from '~/services/database/series'
import { getChapter } from '~/services/database/chapters'
import { getChapterTypesetTexts } from '~/server/export/typesetting/texts'
import { getTypesetPages } from '~/server/export/typesetting/typeset-pages'

const getTypesettingExport = async ({ chapterId, width, splitIntoChunks, chunkSize, format }: ExportChapterTypesettingPayload) => {
  const startTime = performance.now()

  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)
  const exportFilename = `${series?.title} - Chapitre ${chapter.index}`
  const pages = (await getPages(chapterId)).filter(page => page.type === 'clean').sort((a, b) => a.index - b.index)
  const typesetTexts = await getChapterTypesetTexts(chapterId)
  const typesetPages = await getTypesetPages({
    format,
    typesetTexts,
    pages,
    width,
    chunkSize: splitIntoChunks ? chunkSize : undefined
  })

  const zip = new JSZip()
  typesetPages.forEach((page, index) => {
    const pageIndex = `${index.toString().length > 1 ? '' : '0'}${index + 1}`
    if(page.dataURL.length > 1){
      page.dataURL.forEach((chunk, chunkIndex) => {
        const rawBase64 = chunk.split('base64,')[1]
        zip.file(`page-${pageIndex}-chunk-${chunkIndex}.${format}`, rawBase64, { base64: true })
      })
    }else{
      const rawBase64 = page.dataURL[0].split('base64,')[1]
      zip.file(`page-${pageIndex}.${format}`, rawBase64, { base64: true })
    }
  })
  const zipBlob = await zip.generateAsync({ type: "blob" })

  FileSaver.saveAs(zipBlob, `${exportFilename} (lettrage).zip`)

  const endTime = performance.now()
  const generation_time = (endTime - startTime) / 1000
  console.log({ generation_time })
}

export {
  getTypesettingExport
}