import { Image, Point, Textbox, util } from 'fabric'
import { TypesetTextBoundingBox } from '~/editor/typeset-text/typeset-text.interfaces'
import { getFontCSS } from '~/services/fonts/font-css'
import { Tables } from '~/types/supabase'
import { getAbsoluteGradient } from '~/helpers/absolute-gradient'
import { defaultStyles } from '~/services/current-document/text-styles'
import { Gradient, TextStyles } from '~/types/editor/text-styles'
import { CompleteTextStyleDeclaration, TextStyle as FabricTextStyle } from 'fabric'
import { deepmerge } from 'deepmerge-ts'
import TypesetText from '~/editor/typeset-text/typeset-text'

interface GetCharStylesProps{
  textObject: Textbox
  charStyles: TypesetText['charStyles']
}

interface TypesetTextExportProps{
  boundingBox: TypesetTextBoundingBox
  pageImage: Image
  entry: Tables<'typeset_texts'>
}

interface TypesetTextEffectProps{
  mainText: Textbox
  entry: Tables<'typeset_texts'>
}

const getSVGFill = (fill: string | Gradient, textObject: Textbox) => {
  return typeof fill !== 'string' ? getAbsoluteGradient(textObject, fill) : fill
}

const getCharStyles = ({ textObject, charStyles }: GetCharStylesProps) => {
  let styles: FabricTextStyle = {}
  charStyles.forEach(charStyle => {
    // Create an entry with the svgStyle object as the value
    const css = getFontCSS(charStyle.styles.props.fontVariant)
    const fontSize = charStyle.styles.props.fontSize
    const svgStyles: Partial<CompleteTextStyleDeclaration> = {}
    if(css['font-family']) svgStyles.fontFamily = css['font-family']
    if(css['font-weight']) svgStyles.fontWeight = css['font-weight'].toString()
    if(css['font-style']) svgStyles.fontStyle = css['font-style']
    if(fontSize) svgStyles.fontSize = fontSize
    // @ts-expect-error Assumes getSVGFill will always return a valid gradient
    if(charStyle.styles.props.fill) svgStyles.fill = getSVGFill(charStyle.styles.props.fill, textObject)

    charStyle.indices.forEach(globalCharIndex => {
      // Find at which line the char is
      const sliceWithChar = textObject.text.slice(0, globalCharIndex)
      const splitLines = sliceWithChar.split('\n')
      const lineIndex = splitLines.length - 1
      // Find at index the char is for this particular line
      let charsBeforeLine = 0
      splitLines.forEach((line, index) => {
        if(index < lineIndex - 1) charsBeforeLine += line.length
      })
      const charIndex = globalCharIndex - charsBeforeLine
      const styleUpdate = {
        [lineIndex]: {
          [charIndex]: svgStyles
        }
      }
      styles = deepmerge(styles, styleUpdate)
    })
  })
  return styles
}

const getBoundingbox = (pageImage: Image, boundingBoxFromDB: TypesetTextBoundingBox) => {
  const pageTop = pageImage.top
  const pageWidth = pageImage.getScaledWidth()
  const pageHeight = pageImage.getScaledHeight()
  const scaledWidth = boundingBoxFromDB.relativeWidth * pageImage.getScaledWidth()
  const scale = scaledWidth / boundingBoxFromDB.absoluteWidth
  const boundingBox = {
    left: boundingBoxFromDB.startPoint.x * pageWidth,
    top: pageTop + boundingBoxFromDB.startPoint.y * pageHeight,
    width: boundingBoxFromDB.absoluteWidth,
    scaleX: scale,
    scaleY: scale
  }
  return boundingBox
}

const getMainText = ({ entry, pageImage, boundingBox }: TypesetTextExportProps) => {
  const textObject = new Textbox(entry.text ?? '',{
    originX: 'center'
  })
  const styles: TextStyles = entry.style ? JSON.parse(entry.style) : defaultStyles
  const charStyles = entry.char_styles && entry.char_styles[0] !== '{' ? JSON.parse(entry.char_styles) : undefined

  const css = getFontCSS(styles.props.fontVariant)
  const svgStyles = {
    fontFamily: css['font-family'],
    fontWeight: css['font-weight'],
    fontSize: styles.props.fontSize,
    lineHeight: styles.props.lineHeight,
    fill: typeof styles.props.fill !== 'string' ? getAbsoluteGradient(textObject, styles.props.fill) : styles.props.fill,
    textAlign: styles.props.textAlign,
    charSpacing: styles.props.letterSpacing * styles.props.fontSize * 32
  }

  if(styles.props.uppercase){
    textObject.set({
      text: textObject.text.toUpperCase()
    })
  }

  if(charStyles && entry.text){
    textObject.set({
      styles: getCharStyles({ textObject, charStyles })
    })
  }

  textObject.set({
    ...svgStyles,
    ...getBoundingbox(pageImage, boundingBox)
  })

  return textObject
}

const getTextShadows = async ({ entry, mainText }: TypesetTextEffectProps) => {
  const styles: TextStyles = entry.style ? JSON.parse(entry.style) : defaultStyles

  const objectTransform = util.saveObjectTransform(mainText)
  const objectPoint = new Point(objectTransform.left, objectTransform.top)

  const shadows = styles.shadows.map(async (shadow) => {
    const textObject = await mainText.clone()
    textObject.set({
      selectable: false,
      shadow
    })
    const offsetY = (textObject.getScaledHeight() - mainText.getScaledHeight()) / 2
    const top = objectPoint.y - offsetY
    const left = objectPoint.x
    textObject.set({
      top,
      left
    })
    
    return textObject
  })
  return await Promise.all(shadows)
}


const getTextStrokes = async ({ entry, mainText }: TypesetTextEffectProps) => {
  const styles: TextStyles = entry.style ? JSON.parse(entry.style) : defaultStyles

  const objectTransform = util.saveObjectTransform(mainText)
  const objectPoint = new Point(objectTransform.left, objectTransform.top)
  
  const strokes = styles.strokes.map(async (stroke) => {
    const textObject = await mainText.clone()
    textObject.set({
      selectable: false,
      fill: stroke.color,
      stroke: stroke.color,
      strokeWidth: stroke.width,
      strokeLineCap: 'round',
      strokeLineJoin: 'round'
    })

    // Fix offset caused by the stroke width
    const offsetY = (textObject.getScaledHeight() - mainText.getScaledHeight()) / 2
    const top = objectPoint.y - offsetY
    const left = objectPoint.x
    textObject.set({
      top,
      left
    })

    return textObject
  })
  return await Promise.all(strokes)
}

const getTypesetTextObjects = async ({ boundingBox, pageImage, entry }: TypesetTextExportProps) => {
  const mainText = getMainText({ boundingBox, pageImage, entry })
  const shadows = await getTextShadows({ entry, mainText })
  const strokes = await getTextStrokes({ entry, mainText })
  return [...shadows, ...strokes, mainText]
}

export {
  getTypesetTextObjects
}