import { SelectOption } from '~/components/fields/select/select.interfaces'

const exportWidths: SelectOption[] = [
  { label: '720px', value: 720 },
  { label: '760px', value: 760 },
  { label: '800px', value: 800 },
  { label: '1024px', value: 1024 },
  { label: 'Custom', value: 'custom' },
  { label: 'Original', value: 'original' }
]

const exportFormats: SelectOption[] = [
  { label: 'PNG', value: 'png' },
  { label: 'JPG', value: 'jpeg' }
]

export {
  exportWidths, 
  exportFormats
}