import { SubmitHandler, createForm, getValue, valiForm } from '@modular-forms/solid'
import { Component } from 'solid-js'
import Button from '~/components/button/button'
import { ChapterExportFormType, ChapterExportSchema, ExportModalProps } from '~/components/export-modal/export-modal.interfaces'
import styles from '~/components/export-modal/export-modal.module.scss'
import formStyles from '~/components/forms/styles/complex-form.module.scss'
import ToggleField from '~/components/fields/toggle-field/toggle-field'
import { getTypesettingExport } from '~/services/export/typesetting'
import Select from '~/components/fields/select/select'
import TextField from '~/components/fields/text/text'
import { exportFormats, exportWidths } from '~/components/export-modal/form-options'
import { ExportChapterTypesettingPayload } from '~/types/database/chapter'
import Modal from '~/components/modal/modal'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const ExportModal: Component<ExportModalProps> = ({ open, setOpen, chapterId }) => {
  const [form, { Form, Field }] = createForm<ChapterExportFormType>({
    initialValues: {
      width: 800,
      widthPreset: 800,
      chunkSize: 1000,
      splitIntoChunks: false,
      format: 'png'
    },
    validate: valiForm(ChapterExportSchema),
  })

  const onSubmit: SubmitHandler<ChapterExportFormType> = async (values) => {
    setLoadingState({
      loading: true
    })
    let width: ExportChapterTypesettingPayload['width'] = values.width
    const presetAsNumber = parseInt(values.widthPreset.toString())
    if(Number.isInteger(presetAsNumber)) width = presetAsNumber
    if(values.widthPreset === 'original') width = 'original'
    try {
      await getTypesettingExport({
        chapterId,
        splitIntoChunks: values.splitIntoChunks,
        chunkSize: values.chunkSize,
        width,
        format: values.format
      })
    } catch (error) {
      console.error(error)
    }
    setLoadingState({
      loading: false
    })
    setOpen(false)
  }

  const toggleableFieldClasses = (show: boolean) => ({
    [formStyles.toggleableField]: true,
    [formStyles.show]: show
  })
  const showChunkSizeField = () => Boolean(getValue(form, 'splitIntoChunks'))
  const showWidthField = () => getValue(form, 'widthPreset') === 'custom'

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().chapters.forms.export.title}
    >
      <Form onSubmit={onSubmit} class={styles.form}>
        <div class={formStyles.fields}>
          <div class={formStyles.inlineFields}>
            <Field name='widthPreset' type='string'>
              {(field, props) => (
                <Select
                  label={translations().chapters.forms.export.widthPreset}
                  options={exportWidths}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <div classList={toggleableFieldClasses(showWidthField())}>
              <Field name='width' type='number'>
                {(field, props) => (
                  <TextField
                    label={translations().chapters.forms.export.widthValue}
                    type='number'
                    suffix='px'
                    {...field}
                    {...props}
                  />
                )}
              </Field>
            </div>
          </div>
          <div class={formStyles.fields}>
            <Field name='splitIntoChunks' type='boolean'>
              {(field, props) => (
                <ToggleField
                  label={translations().chapters.forms.export.splitIntoChunks}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <div classList={toggleableFieldClasses(showChunkSizeField())}>
              <Field name='chunkSize' type='number'>
                {(field, props) => (
                  <TextField
                    label={translations().chapters.forms.export.splitBy}
                    type='number'
                    suffix='px'
                    {...field}
                    {...props}
                  />
                )}
              </Field>
            </div>
            <Field name='format' type='string'>
              {(field, props) => (
                <Select
                  label={translations().chapters.forms.export.fileFormat}
                  options={exportFormats}
                  {...field}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
        <div class={styles.buttons}>
          <Button
            type='submit'
            style='lagoon'
            isLoading={form.submitting}
          >
            {translations().general.actions.export}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ExportModal