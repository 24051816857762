import { getTypesetTexts } from '~/services/database/typeset-texts'
import { Database } from '~/types/supabase'

type Chapter = Database['public']['Tables']['chapters']['Row']
type TypesetText = Database['public']['Tables']['typeset_texts']['Row']

const reduceToLastVersions = (typesetTexts: TypesetText[]) => {
  const reduced: TypesetText[] = []
  typesetTexts.forEach(typesetText => {
    const alreadyStored = reduced.find(entry => entry.entry_id === typesetText.entry_id)
    if (!alreadyStored) {
      const versions = typesetTexts.filter(entry => entry.entry_id === typesetText.entry_id)
      const sorted = versions.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse()
      reduced.push(sorted[0])
    }
  })
  return reduced
}

const getChapterTypesetTexts = async (chapterId: Chapter['id']) => {
  const typesetTexts = await getTypesetTexts(chapterId)
  const lastVersions = reduceToLastVersions(typesetTexts)
  return lastVersions
}

export {
  getChapterTypesetTexts
}