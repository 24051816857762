import { PageToChunksProps } from '~/types/canvas/exported-page'

const pageToChunks = async ({ canvas, chunkSize, format }: PageToChunksProps) => {
  const chunks: string[] = []

  if (canvas.height < chunkSize){
    chunks.push(canvas.toDataURL())
  }else{
    const chunksToCreate = Math.ceil(canvas.height / chunkSize)
    for(let i = 0; i < chunksToCreate; i++){
      const isLastChunk = i === chunksToCreate - 1
      const remainingHeight = canvas.height - i * chunkSize
      const height = isLastChunk ? remainingHeight : chunkSize
      const chunk = canvas.toDataURL({
        multiplier: 1,
        top: i * chunkSize,
        height,
        format
      })
      chunks.push(chunk)
    }
  }

  return chunks
}

export {
  pageToChunks
}